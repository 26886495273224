const weekdayData = (localization) => {
  return[
  {
    id: "1",
    title: localization.openingtimes.monday.title,
    time: localization.openingtimes.monday.status,
    status: "0"
  },
  {
    id: "2",
    title: localization.openingtimes.tuesday.title,
    time: localization.openingtimes.tuesday.status,
    status: "0"
  },
  {
    id: "3",
    title: localization.openingtimes.wednesday.title,
    time: localization.openingtimes.wednesday.status,
    status: "0"
  },
  {
    id: "4",
    title: localization.openingtimes.thursday.title,
    time: localization.openingtimes.thursday.status,
    status: "0"
  },
  {
    id: "5",
    title: localization.openingtimes.friday.title,
    time: localization.openingtimes.friday.status,
    status: "0"
  },
  {
    id: "6",
    title: localization.openingtimes.saturday.title,
    time: localization.openingtimes.saturday.status,
    status: "1"
  },
  {
    id: "7",
    title: localization.openingtimes.sunday.title,
    time: localization.openingtimes.sunday.status,
    status: "1"
  },
]
}

export {weekdayData}